import { axios } from "../main.ts";

export default {
  post: async function (payload) {
    const { data } = await axios.post("/api/core/returns/search", payload);
    return data.data;
  },
  getLogs: async function (query) {
    const { data } = await axios.get(
      `/api/returns/${query.returnId}/datadog-logs/${query.time}`
    );
    return data.data;
  },
};
